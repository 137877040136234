.collection-block {
  display: flex;
  height: 380px;
  padding-top: 75px;
}
.collection-block .collection-info {
  position: relative;
  width: 360px;
  margin-right: 60px;
  padding-bottom: 55px;
}
.collection-block .collection-info a.rounded_btn_link {
  position: absolute;
  bottom: 60px;
  width: 100%;
}
.collection-block .collection-info a.collection-type {
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: #898d91;
  text-transform: uppercase;
  text-decoration: none;
  margin-bottom: 15px;
}
.collection-block .collection-info a.collection-title {
  font-size: 24px;
  line-height: 1.2;
  font-weight: bold;
  color: #242728;
  text-decoration: none;
}
.collection-books {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: auto;
}
.collection-books img {
  width: calc(100% / 5 - 30px);
  max-width: 170px;
}
.collection_books .collection_books_inner {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.collections_content_bg {
  background: #ecf1f5;
  margin-bottom: -125px;
  padding-bottom: 25px;
}
.collections_topline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 60px;
}
.collection_header {
  font-family: 'Leto Sans', sans-serif, Arial, Helvetica;
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  color: inherit;
  text-decoration: none;
}
.collection_header:hover {
  color: #5c5c61;
}
.collections_topline .ui-selectmenu-button {
  float: right;
  margin-right: 80px;
  border: none;
  width: auto !important;
}
.collection_block {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  padding: 60px 0;
  margin-bottom: 12px;
}
.index_collections_wrapper .collection_block {
  height: 380px;
  align-items: flex-end;
  border-bottom: none;
  padding: 60px 0;
}
.collection_about {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  float: left;
  width: 35%;
  padding-right: 50px;
}
.collection_books {
  float: right;
  width: 65%;
  /*text-align: right;*/
}
.index_collections_wrapper .collection_books_inner {
  width: 100%;
}
.index_collections_wrapper .collection_about small {
  font-size: 13px;
  font-weight: 400;
  color: #b4bac1 !important;
}
.collection_books img {
  width: calc(16.66666667%);
  /*vertical-align: bottom;*/
  margin-left: calc(4.16666667%);
  -moz-transition: all 600ms ease-out;
  -o-transition: all 600ms ease-out;
  -webkit-transition: all 600ms ease-out;
}
.collection_books img:hover {
  -webkit-transform: scale(1.03);
  -moz-transform: scale(1.03);
  -o-transform: scale(1.03);
}
.collection_books img:first-child {
  margin-left: 0;
}
.collection_title {
  font-size: 24px;
  line-height: 1.2;
  font-weight: bold;
  color: #242728;
  text-decoration: none;
}
.index_collections_wrapper .collection_title {
  display: block;
  font-size: 22px;
  line-height: 1.36;
  font-weight: 700;
  color: inherit;
  text-decoration: none;
  margin-bottom: 12px;
}
.collection_title:hover,
.index_collections_wrapper .collection_title:hover {
  color: #5c5c61;
}
.index_collections_wrapper .collection_info {
  margin: 0 0 5px;
}
.index_collections_wrapper .collection_info a {
  color: #898D91;
}
.index_collections_wrapper .collection_info a:hover,
.index_collections_wrapper .collection_info a :focus {
  color: #232325;
  transition: color .2s;
}
.collection_info {
  font-size: 12px;
  color: #b4bac1;
  margin-top: 30px;
}
.collection_info a {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #0000f3;
}
.collection_info a:hover {
  color: #8300f3;
}
.collection_info span {
  display: inline-block;
  margin-left: 10px;
}
.collection_page_line {
  margin-left: auto;
  margin-right: auto;
}
.collection_page_line .collection_header {
  margin-left: 100px;
}
.collection_content_wrapper {
  background-color: #fff;
  border-top: none;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.collection_page_content {
  width: 820px;
  min-height: 300px;
  padding-top: 60px;
  padding-left: 200px;
}
.collection_page_socials {
  position: absolute;
  top: 68px;
  right: -1px;
}
.collection_page_socials .event_socials {
  float: none;
  margin: 0;
}
.collection_page_content .collection_info {
  margin-top: 11px;
}
.collection_comment {
  font-style: italic;
  font-size: 16px;
  margin-top: 33px;
  color: #363334;
}
.collection_comment:before {
  content: ' ';
  display: inline-block;
  width: 27px;
  height: 33px;
  background: url("../images/books_col.png");
  background-size: cover;
  position: relative;
  top: 14px;
  margin-right: 15px;
}
.collection_books_wrapper {
  margin-top: 60px;
}
.collection_books_block {
  border: 1px solid #edf0f1;
  background: #fff;
}
.secondary_wrapper {
  display: inline;
}
.collection_books_block.main_block {
  padding: 80px 80px;
  border-bottom: none;
  margin-bottom: -1px;
}
.collection_books_block.main_block .collection_book_info {
  float: left;
  width: 631px;
}
.collection_books_block.main_block .collection_book_image {
  float: right;
  width: 204px;
}
.collection_books_block.secondary_block {
  float: left;
  width: 600px;
  height: 487px;
  margin-left: -1px;
  margin-bottom: -1px;
  text-align: center;
  padding: 50px;
}
/*.collection_books_block.secondary_block:nth-child(2n+2) {
    border-right: none;
}
.collection_books_block.secondary_block:nth-child(2n+1) {
    border-left: none;
}*/
.collection_books_block.secondary_block .collection_book_image {
  height: 232px;
  margin-bottom: 40px;
}
.collection_books_block.small_block {
  width: 200px;
  float: left;
  height: 244px;
  margin-bottom: -1px;
  margin-left: -1px;
  text-align: center;
  position: relative;
}
.collection_books_block.small_block .collection_book_image {
  max-width: 131px;
  max-height: 190px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* POPUP */
#popup_book.popup_box {
  max-width: 900px;
  padding: 72px;
  border: none;
  box-shadow: 0 0 98px rgba(0, 0, 0, 0.4);
  text-align: left;
}
@media screen and (min-width: 768px) {
  .popup_book_grid {
    display: grid;
    grid-template-columns: 1fr 180px;
    gap: 40px;
  }
}
.popup_book_image {
  max-width: 100%;
  display: block;
  border-radius: 6px;
  border: 1px solid #f5f6f7;
}
.collection_book_meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .5em;
}
.collection_book_author {
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: -0.6px;
  color: #242728;
}
.collection_book_label {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: .02em;
  line-height: 1.5;
  color: #ffffff;
  background: #fa8905;
  border-radius: 2px;
  padding: 3px 8px;
}
.collection_book_title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.35;
  letter-spacing: -0.72px;
  color: #232325;
}
.collection_buttons {
  display: flex;
  margin: 2em 0 .5em;
}
.collection_book_place_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: .5em 0;
  margin-top: 1.5em;
}
.collection_book_place_wrapper span {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #b4bac1;
}
.collection_book_place {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #b4bac1;
}
.collection_book_place:before {
  content: '';
  display: inline-block;
  width: 11px;
  height: 14px;
  background: url("../images/svg/event-place-grey.svg") no-repeat left;
  background-size: 10px;
  padding-left: .5em;
  margin-bottom: -2px;
}
.popup_book_availability {
  font-size: 12px;
  font-weight: 600;
  color: #FFAB0B;
  text-align: center;
  margin-top: 1em;
}
.collection_book_description,
.collection_description,
.collection_book_review {
  font-weight: 400;
  font-size: 16px;
  margin-top: 24px;
}
.collection_book_description p,
.collection_description p,
.collection_book_review p {
  line-height: 27px;
  margin-bottom: 27px;
  margin-top: 0;
}
.collection_book_cite {
  position: relative;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.53;
  color: #232325;
  margin: 30px 0 50px;
}
.collection_book_cite:before {
  content: ' ';
  display: block;
  background-image: url('../images/svg/quote-icon.svg');
  background-size: cover;
  width: 24px;
  height: 15px;
  margin-bottom: 15px;
}
.popup_book_comments {
  color: #909090;
  font-size: 14px;
  line-height: 1.4;
  margin-top: 50px;
}
.collection_links a {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  text-decoration: none;
  color: #B4BAC1;
  margin-left: 20px;
}
.collection_links a:hover {
  color: #232325;
}
.collection_book_bibliog_desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.78;
  color: #B4BAC1;
  margin-top: 1.5em;
  margin-bottom: .5em;
}
.collection_empty {
  margin-top: 40px;
  margin-left: 80px;
  font-size: 18px;
}
.inner_collections_topline {
  margin-top: 70px;
}
#collections.inner_collections .collection_block:last-child {
  border-bottom: none;
}
@media screen and (max-width: 1160px) {
  .collection_books_block.secondary_block {
    width: 490px;
  }
  .collection_books_block.small_block {
    width: 164px;
  }
  .popup_box#popup_book {
    width: 90%;
    padding: 50px;
  }
  .collection_book_cite {
    font-size: 21px;
    padding: 20px 0 20px 100px;
  }
  .collection_book_cite:before {
    margin-left: -100px;
  }
}
@media screen and (max-width: 1000px) {
  .collection_books {
    float: none;
    width: 100%;
    text-align: center;
  }
  .collection_books_block.secondary_block {
    width: 460px;
  }
  .collection_books_block.small_block {
    width: 154px;
  }
  .collection_about {
    float: none;
    width: 100%;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 860px) {
  .index_collections_wrapper .collection_books {
    padding: 0;
  }
  .index_collections_wrapper .collection_block {
    flex-direction: column;
    padding-top: 20px;
  }
  .collections_topline {
    text-align: left;
    margin: 0;
  }
  .collection_header {
    font-size: 20px;
    font-weight: 700;
  }
}
@media screen and (max-width: 520px) {
  .collection_links {
    display: none;
  }
  .collections_topline {
    padding: 0 20px;
    z-index: 2;
  }
  .collection_header {
    margin-left: 0;
  }
  .events_filters {
    display: none;
  }
  .collection_block {
    /*padding: 40px 0 0 !important;*/
    overflow: hidden;
  }
  .collection_about {
    float: none;
    width: 100%;
  }
  .collection_books {
    float: none;
    width: 100%;
    margin-top: 30px;
    overflow: auto !important;
    -webkit-overflow-scrolling: touch !important;
  }
  .collection_books_inner {
    width: 450px;
  }
  .collections_topline .ui-selectmenu-button {
    float: none;
    border: 1px solid #edf0f1;
    margin-top: 20px;
    margin-right: 0;
    margin-bottom: -30px;
  }
  #popup_book.popup_box {
    width: 100%;
    padding: 30px 20px;
    margin-bottom: 0;
  }
  #collections {
    padding: 0 20px;
  }
  .collections_page {
    margin-top: 150px !important;
    position: relative;
    margin-bottom: 60px;
  }
  .collection_page_line {
    margin: 0;
  }
  .collection_page_line .collection_header {
    margin-left: 0;
  }
  .collection_content_wrapper {
    border: none;
    margin: 0;
    margin-top: 20px;
    position: relative;
    padding: 0 20px;
  }
  .collection_page_content {
    width: 100%;
    min-height: 300px;
    padding-top: 30px;
    padding-left: 0;
  }
  .collection_page_socials {
    position: relative;
    top: 0;
    right: 0;
  }
  .collection_books_block.main_block {
    padding: 20px 0;
  }
  .collection_books_block.main_block .collection_book_info {
    float: none;
    width: 100%;
  }
  .collection_books_block.main_block .collection_book_image {
    float: none;
  }
  .collection_books_block.secondary_block {
    float: none;
    width: 100%;
    height: auto;
    margin-left: 0;
    margin-bottom: 0;
    text-align: center;
    padding: 20px 0 0;
    border: none;
  }
  .collection_books_block.small_block {
    width: 100%;
    float: none;
    height: auto;
    text-align: center;
    position: relative;
    border: none;
    margin-left: 0;
    margin-bottom: 20px;
  }
  .collection_books_block.secondary_block .collection_book_image {
    height: auto;
    margin-bottom: 20px;
    max-width: 100%;
  }
  .collection_books_block.small_block .collection_book_image {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    height: auto;
  }
  .collection_books_wrapper {
    background: #fff;
  }
  .collection_book_image.mobile_version {
    width: 100% !important;
    height: auto;
    margin-bottom: 20px;
  }
}
